import HttpService from "../services/HttpService";
import * as React from "react";
import {useEffect, useState} from "react";
import {
    AspectRatio,
    Badge,
    Box,
    Button,
    Center, Divider,
    Flex,
    Heading, Image, Pressable,
    ScrollView, Spacer,
    Spinner,
    Text, useTheme,
    View
} from "native-base";
import Layout from "../layouts/Layout";
import {compareAsc, format, formatDistance, formatDuration, intervalToDuration, parseISO} from "date-fns";
import LinkButton from "../components/LinkButton";
import VideoPlayer from "../components/VideoPlayer";
import {useTranslation} from "react-i18next";
import SessionLectures from "../components/SessionLectures";
import SessionFeatures from "../components/SessionFeatures";
import MyListCheckbox from "../components/MyListCheckbox";
import {useSelector} from "react-redux";
import {Link, useIsFocused} from "@react-navigation/native";
import {Dimensions, Linking, Platform} from "react-native"
import Constants from "expo-constants";
import AccountService from "../services/AccountService";
import Config from "../config.json";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import pusher from "../pusher";
import {marginRight} from "styled-system";

export default function SessionScreen({navigation, ...props}) {
    const [session, setSession] = useState(null);
    const [fetchErr, setFetchErr] = useState();
    const {t, i18n} = useTranslation();
    const {list, congressUser} = useSelector(state => state.auth);
    const isFocused = useIsFocused();
    const {colors} = useTheme();
    const timezone =  congressUser.timezone.replace(/[-_]/g, ' ');
    let isSoon = false;

    function handlePressAcademy(){
        Linking.openURL('https://www.imcas.com/en/academy');
    }

    // Ping
    useEffect(() => {

    }, [isFocused]);

    useEffect(() => {
        let interval;
        if(isFocused) {
            if (props.route.params.id) {
                getSession();

                if(session && compareAsc(new Date(), new Date(session.date_end)) !== 1) {
                    pusher.subscribe('onsite-room');

                    pusher.bind('update', function (data) {
                        setTimeout(function () {
                            getSession();
                        }, 2000);
                    });
                }
            }
            interval = AccountService.startPing(navigation, {
                page: 'session',
                pageId: session ? session.id : null
            });
        }

        return () => {
            setSession(null);
            pusher.unsubscribe('onsite-room');
            clearInterval(interval);
        };
    }, [isFocused]);

    function getSession() {
        HttpService.getData('session/get/one/' + props.route.params.id)
            .then((response) => {
                setSession(response.data.data);
            })
            .catch((err) => {
                setFetchErr(err);
            })
    }

    function renderAlertAd()
    {
        return(
<>
            <AspectRatio w={'35%'} ratio={9/ 1}>
                    <Image
                        source={{uri: session.alert_logo}}

                        style={{
                    }}
                        alt="session image"
                    />
            </AspectRatio>
            <Flex marginTop={10}>
                <Text>
                    {t('this_case_is_a_clinical_case')}
                </Text>
                <Button  onPress={handlePressAcademy}>
                    {t('go_to_imcas_academy')}
                </Button>
            </Flex>
            </>


            );
    }


    function changeStreamLanguage(language) {
        return HttpService.postData('account/change-stream-language', {language: language})
            .then((response) => {
                getSession();
            })
            .catch((error) => {
                //return error;
            });
    }

    function renderTranslations() {
        if (session.replay_available_languages && session.replay_available_languages.length > 1) {
            return (
                <Flex direction={'row'} justify={'center'}>
                    <Text>{t('Available_translations')} : </Text>
                    {
                        session.replay_available_languages.map((language, index) => {
                            if (session.replay_language !== language.code) {
                                return (
                                    <Pressable key={index} onPress={() => changeStreamLanguage(language.code)}
                                               style={{cursor: 'pointer'}}>
                                        <Image
                                            source={{uri: language.flag_url}}
                                            alt={language.code}
                                            style={{
                                                width: 16,
                                                height: 11,
                                                marginTop: 8,
                                                marginRight: 5,
                                                opacity: 0.5
                                            }}/>
                                    </Pressable>
                                );
                            } else {
                                return (
                                    <Image
                                        alt={language.code}
                                        source={{uri: language.flag_url}}
                                        style={{
                                            width: 16,
                                            height: 11,
                                            marginTop: 8,
                                            marginRight: 5,
                                        }}/>
                                );
                            }
                        })
                    }
                </Flex>
            );
        }
    }

    function renderReplay() {
        let fileUrl;

        if (session.replay && session.hasAccess) {

            // PLayer won't play normal file for some reason
            if (Platform.OS === 'android') fileUrl = session.replay.cdn_file_fallback2;
            else fileUrl = session.replay.cdn_file;

            return (
                <>
                    <Flex style={{width: '100%', aspectRatio: 3 / 2}}>
                        <VideoPlayer file={fileUrl}/>

                    </Flex>
                    {renderTranslations()}
                </>
            );
        } else if (session.replay) {
            return (
                <Center bg={'black'} border={1} w={'100%'} h={72} p={4}>
                    <Text>{t('You_dont_have_access_to_this_session')}</Text>
                    <View h={8}/>
                    <LinkButton destination={'Program'} text={'Program'}/>
                </Center>
            );
        }
    }

    function renderTag(count) {
        if (session.liveCurrent?.length > 0 && session.physical_only === 0) {
            if (Platform.OS === 'web') {
                return (
                    <View bg={'red.500'} w={'100%'} borderRadius={5}>
                        <Link to={{screen: 'LiveStageScreenForDesktop', params: {id: session.room_id}}}
                              style={{paddingVertical: '10px', textAlign: 'center'}}>
                            <Text color={'white'}>{t('This_session_is_live_watch_it')}</Text>
                        </Link>
                    </View>
                );
            } else {
                return (
                    <Button
                        colorScheme={'red'}
                        w={'100%'}
                    >{t('This_session_is_live_watch_it')}</Button>
                )
            }
        } else if (session.liveCurrent?.length > 0 && session.physical_only === 1) {
            return (
                <Badge
                    backgroundColor={'red.500'}
                    py={1}
                    borderRadius={'md'}
                    width={'100%'}
                    maxW={900}
                    mb={4}
                >
                    <Text fontSize={{base: 'xs', md: 'md'}} textAlign={'center'} color={'white'}>Live now</Text>
                </Badge>
            );
        } else return (
            <Badge
                backgroundColor={colors['backgroundLight'][50]}
                py={1}
                borderRadius={'md'}
                width={'100%'}
                maxW={900}
                mb={4}
            >
                <Text fontSize={{base: 'xs', md: 'md'}} textAlign={'center'} >{count}</Text>
            </Badge>
        );
    }

    if (fetchErr) {
        return (
            <Layout>
                <Center
                    flex={1}
                >
                    <Text>There was a problem loading your data.</Text>
                    <Text>URL : session/get/{props.route.params.id}</Text>
                </Center>
            </Layout>
        );
    } else if (session) {
        const timeBegin = Date.parse(session?.datetime_begin);
        if (timeBegin > Date.now()) isSoon = true;
        let count = formatDistance(
            new Date(parseISO(session?.datetime_begin)),
            new Date(Date.now()),
            {addSuffix: true}
        );
        let isChecked = false;
        if (list.find(listItem => listItem.entity_id === session?.id)) isChecked = true;
        return (
            <Layout withBackButton activePage={navigation.isFocused ? 'Program' : ''}>
                <ScrollView flex={1} px={2}>
                        <Flex flex={1} flexDirection={'column'} alignItems={Platform.OS === 'web' ? 'center': 'start'} gap={2} mb={2}>
                            <Heading size={'2xl'} textAlign={{base: 'center', md: 'left'}}>{t('Session')}</Heading>
                            <Heading size={Platform.OS === 'web' ? 'lg' : 'xl'} textAlign={{base: 'left', md: 'center'}}>{session?.title}</Heading>
                        </Flex>
                        <Heading size={Platform.OS === 'web' ? 'md' : 'lg'} textAlign={{base: 'left', md: 'center'}} fontWeight={"medium"} mb={5}>{session.subtitle}</Heading>

                    <Flex align={'center'}>
                        {renderTag(count)}
                    </Flex>

                    {renderReplay()}

                    <Flex
                        direction={'row'}
                        alignItems='center'
                        justifyContent={'space-between'}
                        width={'100%'}
                        maxW={900}
                        maxH={Platform.OS === 'web' ? 'unset' : '200px'}
                        alignSelf={'center'}
                    >
                        <Flex width={'80%'} my={2} align={'flex-start'}>
                            <Flex direction={'row'}>
                                <Text fontSize={{
                                    base: 'xs',
                                    md: 'md'
                                }}>{format(parseISO(session.datetime_begin), 'EEEE')}</Text>
                                <Text fontSize={{
                                    base: 'xs',
                                    md: 'md'
                                }}>, {format(parseISO(session.datetime_begin), 'p')}</Text>
                                <Text fontSize={{
                                    base: 'xs',
                                    md: 'md'
                                }}> - {format(parseISO(session.datetime_end), 'p')} ({timezone})</Text>
                            </Flex>
                            <Text fontSize={{base: 'xs', md: 'md'}}>
                                {formatDuration(intervalToDuration({
                                    start: parseISO(session.datetime_begin),
                                    end: parseISO(session.datetime_end)
                                }), {format: ['hours', 'minutes']})}
                            </Text>
                            <Text marginBottom={Platform.OS === 'web' ? 100 :5} fontSize={{base: 'xs', md: 'md'}}>{session.room_name}</Text>
                            {(session && session.is_alert)?renderAlertAd():null}
                        </Flex>
                        <Flex>
                            <MyListCheckbox
                                item={{id: session?.id, type: 'LectureSession'}}
                                size={'sm'}
                                isChecked={isChecked}
                            />
                        </Flex>
                    </Flex>

                    { Platform.OS === 'web' ?
                        <Box p={2}/>
                        : <></>
                    }
                    <SessionFeatures session={session}/>
                    <SessionLectures lectures={session.lectures}/>
                </ScrollView>
            </Layout>
        );
    } else {
        return (
            <Layout withBackButton>
                <Center
                    flex={1}
                >
                    <Spinner color="#5766D6" />
                </Center>
            </Layout>
        );
    }
}
