import * as React from "react";
import {Text, Input, Select, CheckIcon, VStack, HStack, Button, Modal, Flex, Spacer, useTheme} from "native-base";
import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Select2Form from "./Select2Form";
import {Platform} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

function EposterFilters({keywords, eposters, setEpostersFiltered, isOpen, toggleFilters}) {
    const {t, i18n} = useTranslation();
    const [keywordSelected, setKeywordSelected] = useState("");
    const {colors} = useTheme();
    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };


    const arrayOfKeywords = () => {
        if (eposters.length > 0) {
            let filteredKeywords = [];
            let keywords = [];
            eposters.forEach(eposter => keywords.push(...eposter?.keywords.map(k => {
                return {
                    id: k.id,
                    keyword: k.name
                }
            })));
            keywords.forEach(k => {
                if (!filteredKeywords.find(fk => fk.id === k.id)) {
                    filteredKeywords.push(k);
                }
            })
            return filteredKeywords;
        }
    }

    const applyFilter = () => {
        let arr = [];
        if (keywordSelected !== "") {
            for (let eposter of eposters) {
                if (eposter?.keywords?.length > 0) {
                    for (let k of eposter.keywords) {
                        if (k.name.toLowerCase() === keywordSelected.toLowerCase()) {
                            arr = [...arr, eposter]
                        }
                    }
                }
            }
            setEpostersFiltered(arr);
        }
    }

    const clearFilters = () => {
        setEpostersFiltered(eposters);
        setKeywordSelected('')
    }

    function applyAndToggleFilters() {
        applyFilter();
        toggleFilters();
    }

    return (
        <Modal isOpen={isOpen} onClose={toggleFilters}>
            <Modal.Content maxWidth={'600px'}>
                <Modal.CloseButton/>
                <Modal.Body pt={8}>
                    {
                        arrayOfKeywords()?.length > 0 && (
                            Platform.OS == "web" ?
                            <Select
                                selectedValue={keywordSelected}
                                placeholder={keywordSelected == "" ? t("Filter_by_keywords") : keywordSelected}
                                _selectedItem={{
                                    bg: "teal.600",
                                    endIcon: <CheckIcon size="5"/>,
                                }}
                                p={2}
                                my={2}
                                onValueChange={(itemValue) => setKeywordSelected(itemValue)}
                            >
                                <Select.Item label="" value=""/>
                                {arrayOfKeywords()?.map((c, index) => (
                                    <Select.Item key={c.id} index={index} label={c.keyword} value={c.keyword}/>))}
                            </Select>
                                :
                                <Select
                                    styles={{
                                        marginTop : 8,
                                        opacity:0.4,
                                    }}
                                    selectedValue={keywordSelected}
                                    placeholder={keywordSelected === "" ? t("Filter_by_keywords") : keywordSelected}
                                    _selectedItem={{
                                        bg: colors.white,
                                        paddingRight:2,
                                        _icon: {
                                            color: '#fff'
                                        },
                                        _text: {
                                            overflow: "hidden",
                                            whiteSpace: "normal",
                                        },
                                        startIcon: <MaterialIcons name={"radio-button-checked"} size={20} color={colors['action'][50]}></MaterialIcons>,
                                    }}
                                    _item={{
                                        bg:colors.white,
                                        paddingRight:10,
                                        _text: {
                                            overflow: "hidden",
                                            whiteSpace: "normal",
                                        },
                                        startIcon:<MaterialIcons name={"radio-button-unchecked"} size={20} color={colors}></MaterialIcons>
                                    }}
                                    _customDropdownIconProps={{
                                        size: 5
                                    }}
                                    _actionSheetContent={{
                                        bg: colors.white
                                    }}
                                    p={2}
                                    my={2}
                                    onValueChange={(itemValue) => setKeywordSelected(itemValue)}
                                >
                                    <Select.Item label="" value=""/>
                                    {arrayOfKeywords().map(c => (
                                        <Select.Item index={c.keyword} label={c.keyword} value={c.keyword} key={c.id}/>))}
                                </Select>
                        )
                    }
                    <Flex direction={'row'} mt={4}>
                        <Button
                            flex={10}
                            onPress={clearFilters}
                            variant={'outline'}
                            _text={{
                                color: colors['action'][50]
                            }}
                        >
                            {t('Reset_filters')}
                        </Button>
                        <Spacer flex={1}/>
                        <Button
                            flex={10}
                            onPress={applyAndToggleFilters}>Apply filters</Button>
                    </Flex>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
}

export default EposterFilters
