import {Badge, Flex, HStack, Pressable, Text, useTheme, View} from "native-base";
import {format, parseISO} from "date-fns";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "@react-navigation/native";
import { MaterialCommunityIcons} from "@expo/vector-icons";
import {Linking, Platform} from "react-native";

export default function Lecture(props) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const uploads = {
        'warnings': '#ffc107',
        'success': '#198754',
        'error': '#dc3545'
    }
    const sessionTime  = props.lecture && props.lecture.session ? `${format(new Date(parseISO(props.lecture.session.datetime_begin)), 'HH:mm a')} - ${format(new Date(parseISO(props.lecture.session.datetime_end)), 'HH:mm a')}` :'';
    const lectureTime = props.lecture ? `${format(new Date(parseISO(props.lecture.datetime_begin)), 'HH:mm a')} - ${format(new Date(parseISO(props.lecture.datetime_end)), 'HH:mm a')}` : '';

    const renderBadge = useMemo(() => {
        if (props.lecture.presentation_file_status === 'non received yet') {
            return (
                <>
                    <Badge m={1} bg={uploads.error} alignSelf={Platform.OS ? "center" : 'start'} rounded={'md'}>
                        <Text numberOfLines={2} fontSize={Platform.OS === 'web' ? 'sm' : 'xs'} color={'white'} bold>{t('file_non_received')}</Text>
                    </Badge>
                    <Link to={{screen: 'Map', params: {id: 1027, type: 'room'}}} style={{marginVertical: Platform.OS === 'web' ? 0 : 6, position: 'absolute', width: '100%', height: '100%'}}></Link>
                </>
            )
        }
        else if (props.lecture.presentation_file_status === 'accepted' && props.lecture.file_url) {
            return (
                <Badge m={1} bg={uploads.success} alignSelf="center" rounded={'md'} style={{marginVertical: Platform.OS === 'web' ? 0 : 6}}>
                    <Text fontSize={Platform.OS === 'web' ? 'sm' : 'xs'} color={'white'} bold>{t('file_accepted')}</Text>
                </Badge>
            )
        } else if (props.lecture.presentation_file_status === 'received: pending decision' && props.lecture.file_url) {
            return (
                <Badge m={1} bg={uploads.warnings} alignSelf="center" rounded={'md'} style={{marginVertical: Platform.OS === 'web' ? 0 : 6}}>
                    <Text fontSize={Platform.OS === 'web' ? 'sm' : 'xs'} color={'white'} bold>{t('file_non_received')}</Text>
                </Badge>
            )
        }
    });

    function goToSeeEposter() {
        if (props.lecture.presentation_file_status === 'accepted' && props.lecture.file_url) {
            return Linking.openURL(props.lecture.file_url);
        }
    }

    const renderKeywords = useMemo(() => {
       let keywords = '';
       props.lecture.keywords.forEach((keyword, index) => {
           if (props.lecture.keywords.length - 1 !== index) {
               keywords += `${keyword.name}, `;
           } else {
               keywords += keyword.name;
           }
       });
        return (<Text mt={2} fontSize={'xs'} italic><Text fontSize={'xs'} bold>{t('Keywords')}:</Text> {keywords}</Text>)
    });

    if (Platform.OS === 'web') {
        return (
            <>
                <Flex w={"100%"} px="2" py="2" >
                    <HStack
                        alignItems="center"
                        minW={"100%"}
                        w="100%"
                        py="4"
                        bg={colors['background'][50]}
                        borderRadius='lg'
                        px={"2"}
                        style={{shadowColor:'#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.30, shadowRadius: 6, elevation: 5, border: `2px solid ${colors['background'][200]}`}}
                    >
                        {
                            props.lecture.is_eposter ?
                                <View h={'100%'} justifyContent={"center"} style={{borderRight: `2px solid ${colors['background'][100]}`}}>
                                    <Badge m={1} bg={colors['backgroundLight'][400]} alignSelf="flex-start" rounded={'md'} style={{marginVertical: Platform.OS === 'web' ? 0 : 6}}>
                                        <Text fontSize={Platform.OS === 'web' ? 'sm' : 'xs'} color={'white'} bold>{props.lecture.session.title}</Text>
                                    </Badge>
                                </View>
                                :
                                <View h={'100%'} justifyContent={"center"} style={{borderRight: `2px solid ${colors['background'][100]}`}}>

                                    <Text mr={2} bold>{sessionTime}</Text>
                                </View>
                        }
                        <Flex w={"80%"} ml={2}>
                            <Flex flexDirection={'row'} alignItems={"center"} justifyContent={"space-between"} mb={2}>
                                <Link to={{screen: 'Map', params: {id: props.lecture.is_eposter ? props.lecture.session.room.room_id : props.lecture.session.room_id, type: 'room'}}} >
                                    <Flex flexDirection={"row"}>
                                        <MaterialCommunityIcons name="map-marker" size={18} color="#1f2357" />
                                        <Text fontSize={'sm'} bold>{t('Room')}: {props.lecture.session.room.name}</Text>
                                    </Flex>
                                </Link>
                                {renderBadge}
                            </Flex>
                            <Flex flexDirection={Platform.OS === 'web' ? 'row' : 'column'} justifyContent={"space-between"}>
                                <Link to={{screen: 'Session', params: {id: props.lecture.session.id}}} w={'50%'}>
                                    {
                                        props.lecture.is_eposter ?
                                            <Flex mr={4} alignSelf={"center"}>
                                                <Text p={2} fontSize="md" bold bg={colors['backgroundLight'][50]} color={colors['backgroundLight'][600]}>{props.lecture.title}</Text>
                                            </Flex>
                                            :
                                            <Flex mr={4} alignSelf={"center"}>
                                                <Text p={2} fontSize="md" bold bg={colors['backgroundLight'][50]} color={colors['backgroundLight'][600]}>{props.lecture.session.title}</Text>
                                                <Text p={2} fontSize="md" bold bg={colors['backgroundLight'][50]} color={colors['backgroundLight'][600]}>{props.lecture.session.subtitle}</Text>
                                            </Flex>
                                    }
                                </Link>
                                <Flex w={'50%'}>
                                    {
                                        props.lecture.is_eposter ?
                                            <>
                                                {renderKeywords}
                                                <Pressable onPress={goToSeeEposter} border={`1px solid ${colors['action'][50]}`} alignSelf={"center"} borderRadius={'lg'} mt={4}>
                                                    <Text p={2} color={colors['action'][50]}>{t('See_Eposter')} <MaterialCommunityIcons name={'arrow-right'} color={colors['action'][50]} /></Text>
                                                </Pressable>
                                            </>
                                            :
                                            <>
                                                <Text fontSize="md" pb={2}>{t('lecture_details')}:</Text>
                                                <Text fontSize="sm">{lectureTime}</Text>
                                                <Text fontSize={'sm'} bold>{props.lecture.title}</Text>
                                            </>
                                    }
                                    {props.lecture.treatment_room ?
                                        <Flex mt={2} style={{borderTop: `2px solid ${colors['background'][100]}`}} >
                                            <Flex flexDirection={"row"} pt={2}>
                                                <MaterialCommunityIcons name="map-marker" size={18} color="#1f2357" />
                                                <Text fontSize={'sm'} bold>{t('treatment_room')}: </Text>
                                            </Flex>
                                            <Text fontSize={'sm'}>{props.lecture.treatment_room}</Text>
                                        </Flex>
                                        :
                                        <></>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </HStack>
                </Flex>
            </>
        )
    } else if (Platform.OS !== 'web' && props.lecture.is_eposter) {
        return (
            <>
                <Flex w={"100%"} px="2" py="2" >
                    <HStack
                        alignItems="center"
                        minW={"100%"}
                        w="100%"
                        py="4"
                        bg={colors['background'][50]}
                        borderRadius='lg'
                        px={"2"}
                        style={{shadowColor:'#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.30, shadowRadius: 6, elevation: 5, border: `2px solid ${colors['background'][200]}`}}
                    >
                        <Flex w={"100%"} ml={2}>
                            <Flex flexDirection={'row'} alignItems={"center"} justifyContent={"space-between"} mb={2}>
                                <Flex flexDirection={"row"}>
                                    <MaterialCommunityIcons name="map-marker" size={18} color="#1f2357" />
                                    <Text fontSize={'sm'} bold>{t('Room')}: {props.lecture.session.room.name}</Text>
                                    <Link to={{screen: 'Map', params: {id: props.lecture.session.room.room_id, type: 'room'}}} style={{position: 'absolute', width: '100%', height: '100%'}}></Link>
                                </Flex>
                                {renderBadge}
                            </Flex>
                            <Flex flexDirection={Platform.OS === 'web' ? 'row' : 'column'} justifyContent={"space-between"} w={'100%'}>
                                <Flex mr={4} alignSelf={"center"}>
                                    <Text p={2} fontSize="sm" bold bg={colors['backgroundLight'][50]} color={colors['backgroundLight'][600]}>{props.lecture.title}</Text>
                                    <Link to={{screen: 'Session', params: {id: props.lecture.session.id}}} style={{position: 'absolute', width: '100%', height: '100%'}}></Link>
                                </Flex>
                                {renderKeywords}
                                <Pressable onPress={goToSeeEposter} border={`1px solid ${colors['action'][50]}`} alignSelf={"center"} borderRadius={'lg'} mt={4}>
                                    <Text p={2} color={colors['action'][50]}>{t('See_Eposter')} <MaterialCommunityIcons name={'arrow-right'} color={colors['action'][50]} /></Text>
                                </Pressable>
                            </Flex>
                        </Flex>
                    </HStack>
                </Flex>
            </>
        )
    } else {
        return (
            <>
                <Flex w={"100%"} px="2" py="2" bg={'red'}>
                    <View alignItems="center"
                          minW={"100%"}
                          w="100%"
                          py="4"
                          bg={colors['background'][50]}
                          borderRadius='lg'
                          px={"2"}
                          style={{shadowColor: '#000', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.15, shadowRadius: 4, elevation: 5}}>
                        <View alignItems={"center"} mb={2} w={'100%'} >
                            <Text bold>{sessionTime}</Text>
                        </View>
                        <Flex w={"80%"} ml={2}>
                            <Flex flexDirection={'column'} alignItems={"center"} justifyContent={"space-between"} mb={2}>
                                    <Flex flexDirection={"row"}>
                                        <MaterialCommunityIcons name="map-marker" size={18} color="#1f2357" />
                                        <Text fontSize={'sm'} bold>{t('Room')}: {props.lecture.session.room.name}</Text>
                                    </Flex>
                                <Link to={{screen: 'Map', params: {id: props.lecture.session.room.room_id, type: 'room'}}} style={{position: 'absolute', width: '100%', height: '100%'}}></Link>
                            </Flex>
                            <Flex flexDirection={'column'} justifyContent={"space-between"}>
                                <Flex mb={2} alignSelf={"center"} w={'100%'}>
                                    <Text px={2} pt={2} fontSize="md" bold bg={colors['backgroundLight'][50]} color={colors['backgroundLight'][600]}>{props.lecture.session.title}</Text>
                                    <Text px={2} pb={2} fontSize="md" bold bg={colors['backgroundLight'][50]} color={colors['backgroundLight'][600]}>{props.lecture.session.subtitle}</Text>
                                    <Link to={{screen: 'Session', params: {id: props.lecture.session.id}}} style={{position: 'absolute', width: '100%', height: '100%'}}></Link>
                                </Flex>
                                <Flex>
                                    <Text fontSize="md" pb={2}>{t('lecture_details')}:</Text>
                                    <Text fontSize={"sm"}>{lectureTime}</Text>
                                    <Text fontSize={'sm'} bold>{props.lecture.title}</Text>
                                    {renderBadge}
                                    {props.lecture.treatment_room ?
                                        <Flex mt={2}  style={{borderTop: `2px solid ${colors['background'][100]}`}}>
                                            <Flex flexDirection={"row"} pt={2}>
                                                <MaterialCommunityIcons name="map-marker" size={18} color="#1f2357" />
                                                <Text fontSize={'sm'} bold>{t('treatment_room')}: </Text>
                                            </Flex>
                                            <Text fontSize={'sm'}>{props.lecture.treatment_room}</Text>
                                        </Flex>
                                        :
                                        <></>
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </View>
                </Flex>
            </>
        )
    }


}
