import HttpService from "../services/HttpService";
import {useEffect, useState} from "react";
import Layout from "../layouts/Layout";
import {Center, Flex, Heading, ScrollView, Spinner, Stack, Text, useTheme, View} from "native-base";
import * as React from "react";
import {Link, useIsFocused} from "@react-navigation/native";
import {Dimensions, Platform} from "react-native";
import EposterCard from "../components/EposterCard";
import {useTranslation} from "react-i18next";
import CountResult from "../components/CountResult";
import FilterButton from "../components/FilterButton";
import EposterFilters from "../components/EposterFilters";
import AccountService from "../services/AccountService";
import * as Device from "expo-device";

export default function EpostersListScreen(props) {
    const [eposters, setEposters] = useState([]);
    const [epostersFiltered, setEpostersFiltered] = useState([]);
    const [fetchError, setFetchError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const [showFilters, setShowFilters] = useState(false);
    const [deviceType,setDeviceType] = useState();
    const isFocused = useIsFocused();


    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(props.navigation, {page: 'eposters-list' });
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    function getEposters() {
        setIsLoading(true);
        HttpService.getData('eposters/get/all')
            .then((response) => {
                setIsLoading(false);
                setEposters(response.data.data);
                setEpostersFiltered(response.data.data);
            })
            .catch((error) => {
                setIsLoading(false);
                setFetchError(error);
            });
    }

    useEffect(() => {
        getEposters();
        async function setLayout() {
            const type = await getDeviceType();
            setDeviceType(type);
        }
        setLayout();
    }, []);


    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    function addView(eposter) {
        return HttpService.postData(`eposters/view/hit/${eposter.id}`)
            .then((response) => {
                console.log("ok : ", eposter.id)
            })
            .catch((error) => {
                console.log("error")
                return error;
            });
    }

    function renderEposters() {
        if (epostersFiltered?.length > 0) {
            let items = [];
            epostersFiltered?.forEach((poster) => {

                if (Platform.OS === 'web') {
                    items.push(
                        <View  style={{
                            marginTop: 16,
                            marginBottom: 16,
                            marginRight: 16,
                            marginLeft: 16,
                        }}>
                            <EposterCard poster={poster}/>
                            <Link
                                to={{screen: 'Eposter', params: {file: poster.file_url}}}
                                style={{position: 'absolute', width: '100%', height: '100%'}}
                                onPress={() => addView(poster)}
                            ></Link>
                        </View>);
                } else {
                    items.push(
                        <View key={uniqueId()} my={2} m={{lg:2}}>
                            <EposterCard poster={poster}/>
                            <Link
                                to={{screen: 'Eposter', params: {file: poster.file_url}}}
                                style={{position: 'absolute', width: '100%', height: '100%'}}
                                onPress={() => addView(poster)}
                            ></Link>
                        </View>
                    );
                }
            });

            return (
                <Flex direction={{base: 'column', md: 'row'}} wrap={'wrap'} justify={'center'}>
                    <Flex
                        w={'100%'}
                        alignItems='center'
                        justifyContent={{base: 'space-between', md: 'center'}}
                        direction={{base: 'row', md: 'column'}}
                    >
                        <Heading>{t('E_posters')}</Heading>
                        {Platform.OS === 'web' ? <></>
                            :
                            <View alignSelf={'flex-start'} left={{md: 40}} py={{base: 5, md: 2}}>
                                <CountResult result={epostersFiltered?.length}/>
                            </View>
                        }
                    </Flex>
                    {items}
                </Flex>
            );
        }
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    if (fetchError) {
        return (
            <Layout withBackButton>
                <Center flex={1}>
                    <Text>{t("There_was_a_problem_loading_your_data")}</Text>
                </Center>
            </Layout>
        );
    } else if (eposters.length > 0) {
        return (
            <Layout withBackButton>
                <ScrollView
                    flex={1} px={2}
                    mt={6}
                >
                    {
                        deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height ?
                            <Center>
                                <View maxW={900} margin={Platform.OS === 'web' ? 'auto' : 0}>
                                    {/*<Heading size={'2xl'}>{t('E_posters')}</Heading>*/}
                                    <EposterFilters
                                        eposters={eposters}
                                        setEpostersFiltered={setEpostersFiltered}
                                        isOpen={showFilters}
                                        toggleFilters={toggleFilters}
                                    />
                                    {/*<CountResult result={epostersFiltered?.length}/>*/}
                                    {renderEposters()}

                                </View>
                            </Center>
                            :
                            <View maxW={900} margin={Platform.OS === 'web' ? 'auto' : 0}>
                                {/*<Heading size={'2xl'}>{t('E_posters')}</Heading>*/}
                                <EposterFilters
                                    eposters={eposters}
                                    setEpostersFiltered={setEpostersFiltered}
                                    isOpen={showFilters}
                                    toggleFilters={toggleFilters}
                                />
                                {/*<CountResult result={epostersFiltered?.length}/>*/}
                                {renderEposters()}

                            </View>
                    }

                </ScrollView>
                <FilterButton toggleFilters={toggleFilters}/>
                {Platform.OS === 'web' ?
                    <View position={'absolute'} right={20} top={20} py={{base: 5, md: 2}}>
                        <CountResult result={epostersFiltered?.length}/>
                    </View>
                    : <></>
                }
            </Layout>
        );
    } else if(isLoading) {
        return (
            <Layout withBackButton>
                <Center flex={1}>
                    <Spinner color="#5766D6" />
                </Center>
            </Layout>
        );
    } else {
        return (
            <Layout withBackButton>
                <Center flex={1}>
                    <Text>Sorry, this section appears to be empty.</Text>
                </Center>
            </Layout>
        );
    }
}
