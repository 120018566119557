import {Badge, Button, Flex, Text, useTheme} from "native-base";
import AvatarWithText from "./AvatarWithText";
import * as React from "react";
import {useState,useEffect} from "react";
import {Dimensions, Linking, Platform} from "react-native";
import * as Device from "expo-device";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";

export default function EposterCard(props) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [deviceType,setDeviceType] = useState();
    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    useEffect(() => {
        async function setLayout() {
            const type = await getDeviceType();
            setDeviceType(type);

        }
        setLayout();
    }, []);

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    const renderText = () => {
        return props.poster.is_eposter_sponsored ? t('Sponsored_e_poster') : t('Scientific_e_poster');
    }

    return(
        <Flex
            key={props.poster.id}
            justify={'space-between'}
            w={{base: '100%', md: '400px'}}
            h={Platform.OS === 'web' ? '100%' : deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height ? 250 : "auto"}
            bg={isHovered ? colors['background'][100]['500'] : colors['background'][100]}
            borderRadius={'md'}
            px={Platform.OS === 'web' ? 4 : 2}
            pt={4}
            pb={2}
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
        >
            <Badge m={1} bg={props.poster.is_eposter_sponsored ? '#fa7732' : '#0781aa'} alignSelf="end" rounded={'md'} style={{marginVertical: Platform.OS === 'web' ? 0 : 6}}>
                <Text fontSize={Platform.OS === 'web' ? 'sm' : 'xs'} color={'white'} bold>{renderText()}</Text>
            </Badge>
            <Text mt={2} fontSize={'lg'}>{props.poster.title}</Text>
            <Flex alignItems={"flex-end"} mt={2}>
                <Text underline>{t('Click_to_see_eposter')}<MaterialCommunityIcons size={20} name={'arrow-right'}></MaterialCommunityIcons></Text>
            </Flex>
            <Flex h={12} pr={Platform.OS === 'web' ? 6: 2} align={Platform.OS === 'web' ? 'flex-end' : 'flex-start'} borderTopWidth={1} borderColor={colors['background'][50]} mt={8}>
                <AvatarWithText
                    picture={props.poster.user.picture}
                    text={props.poster.user.fullname}
                    size={'sm'}
                    avatarSize={'sm'}
                    direction={'row'}
                />
            </Flex>
        </Flex>
    );
}
