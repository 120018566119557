import * as React from "react";
//import Pdf from 'react-native-pdf';

import Layout from "../layouts/Layout";
import {Button, Center, Flex, Heading, HStack, Text, View} from "native-base";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import AccountService from "../services/AccountService";
import {useIsFocused} from "@react-navigation/native";
import {Platform} from "react-native";
import {pdfjs, Document, Page} from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {MaterialCommunityIcons} from "@expo/vector-icons";

export default function EposterScreen(props) {
    const {t, i18n} = useTranslation();
    const isFocused = useIsFocused();
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    // Ping
    useEffect(() => {
        let interval;
        if (isFocused) {
            interval = AccountService.startPing(props.navigation, {page: 'eposter'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    function onLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    if (props.route.params.file) {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? 'Explore' : ''}>
                <Heading size={'2xl'} mb={4}>{t('E_poster')}</Heading>
                <View flexDirection="row" justifyContent="center">
                    <Document file={props.route.params.file} onLoadSuccess={onLoadSuccess}  >
                        <Page
                            pageNumber={pageNumber}
                            width={800}
                        />
                    </Document>
                </View>
                {numPages > 1 ?
                    <Flex justifyContent={"center"} alignItems={"center"} direction={"row"} style={{gap:'2rem'}} mt={10} pb={10}>
                        <Button onPress={goToPrevPage}>
                            <MaterialCommunityIcons color={'white'} name={'chevron-left'}></MaterialCommunityIcons>
                        </Button>
                        <Text>{pageNumber} of {numPages}</Text>
                        <Button onPress={goToNextPage}><MaterialCommunityIcons color={'white'} name={'chevron-right'}></MaterialCommunityIcons></Button>
                    </Flex>
                    :<></>
                }

            </Layout>
        );
    } else
        return (
            <Layout activePage={props.navigation.isFocused ? 'Explore' : ''}>
                <Center>
                    <Text>Sorry, we couldn't find the file you are looking for.</Text>
                </Center>
            </Layout>
        );
}
