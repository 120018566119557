import * as React from "react";
import {Flex, Heading, Link as LinkNativeBase, Pressable, Text, useTheme, View} from "native-base";
import {Link} from "@react-navigation/native";
import {Linking, Platform} from "react-native";
import {useTranslation} from "react-i18next";
import {AntDesign} from '@expo/vector-icons';


export default function CompanyFiles(props) {

    const {t, i18n} = useTranslation();
    const {colors} = useTheme();

    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    function goToFile(url) {
        return Linking.openURL(url);
    }

    const renderCompanyFiles = () => {
        if (props.files?.length > 0) {
            let items = [];
            props.files?.forEach((file, index) => {
                if (Platform.OS === 'web') {
                    items.push(
                        <Flex
                            flexDirection={'row'}
                            bg={colors['background'][100]}
                            alignItems={'center'}
                            borderRadius={'md'}
                            px={6} py={2} w={'100%'}
                        >
                            <AntDesign name="pdffile1" size={24} color={"#1F2357"}/>
                            <Text textAlign='center' ml={4}>{file.title}</Text>
                            <Link
                                to={{screen: 'CompanyFiles', params: {file: file.url}}}
                                style={{position: 'absolute', width: '100%', height: '100%'}}
                            />
                        </Flex>

                    );
                } else {
                    items.push(
                        <View
                            key={uniqueId()}
                            bg={colors['background'][100]}
                            borderRadius={'md'}
                            p={2}
                            m={2}
                        >
                            <Flex
                                flexDirection={'row'}
                                bg={colors['background'][100]}
                                alignItems={'center'}
                                borderRadius={'md'}
                                px={4} py={1} w={'100%'}
                            >
                                <AntDesign name="pdffile1" size={24} color={"#1F2357"}/>
                                <Text textAlign='center' ml={4}>{file.title}</Text>
                                  <Link
                                to={{screen: 'CompanyFiles', params: {file: file.url}}}
                                style={{position: 'absolute', width: '100%', height: '100%'}}
                                border={1}
                                borderColor={'white'}
                            />
                            </Flex>
                        </View>
                    );
                }
            });

            return (
                <>
                    <Flex
                        wrap='wrap'
                        justifyContent={{base: 'center'}}
                        alignContent={{base: 'center'}}
                        width={{base: '100%'}}
                    >
                        {items}
                    </Flex>
                </>
            )

        }
    }

    return (
        <>
            {renderCompanyFiles()}
        </>
    );
}
